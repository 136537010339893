.MobileNav
{
     position: fixed;
     justify-content: center;
     bottom: 0;
     width: 100%;
     display: none;
     background-color: rgb(255, 255, 255);
     height: 40px;
     align-items: center;
     border-top: 1px solid rgb(225, 225, 225);
     z-index: 2;
     gap: 80px;
}
@media screen and (max-width: 767px) 
{
.MobileNav
{
    display: flex;
}
}