html
{
  overflow-x: hidden;
}
.artplayer 
  {
    top: 25px;
    width: 65vw;
    height: 74vh;
    margin: 0px 50px 0px;
    z-index: 0;
    position: relative;
  }
  .artplayerframe
  {
    top: 25px;
    width: 65vw;
    height: 74vh;
    margin: 0px 50px 0px;
    z-index: 0;
    position: relative;
    background-color: black;
  }
  @media screen and (max-width:767px)
  {
  .artplayer {
    position: relative;
    top: 100px;
    width: 100%;
    height: 35vh;
    margin: 0px 0px 0px;
    z-index: 0;
    margin-top: -100px;
    
  }
  .artplayerframe {
    position: relative;
    top: 100px;
    width: 100%;
    height: 35vh;
    margin: 0px 0px 0px;
    z-index: 0;
    margin-top: -100px;
    background-color: black;
    
  }
}